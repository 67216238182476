import { BigButton } from "../BigButton";
import { formatPrice } from "~/utils/strings";
import { useFetcher, useLoaderData, useNavigate } from "@remix-run/react";
import { CloseIcon } from "../../assets/icons/Close";
import { useRef } from "react";
import { useModal } from "../../context/Modal";
import type { Cart } from "../../../../types/ShoppingCart";
import { calculatePrice, calculateTotalPrice } from "~/utils/price";
import { useCloseOnClickOutside } from "~/hooks/useCloseOnClickOutside";
import { GetSmallUrl } from "~/utils/images";
import { INTENTS } from "~/utils/intents";
import type { CartItem } from "../../../../types/CartItem";
import { RightPanelWrapper } from "../RightPanelWrapper";
import { H5 } from "../H5";
import {
  FREE_SHIPPING_AMOUNT,
  LIMIT_TO_OFFER_FREE_SHIPPING,
} from "~/utils/constants";

export const ShoppingCart = () => {
  const loaderData = useLoaderData<{
    cart: Cart;
  }>();
  const { shoppingCartVisible, setShoppingCartVisible } = useModal();
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  useCloseOnClickOutside(() => {
    setShoppingCartVisible("hidden");
  }, ref);

  if (shoppingCartVisible !== "shoppingCart") return null;

  const amount = calculateTotalPrice(loaderData.cart);
  const missingForFreeShipping = FREE_SHIPPING_AMOUNT - amount;
  //deep copy
  let items: CartItem[] = [];
  for (let i = 0; i < loaderData.cart.items.length; i++) {
    items.push({
      product: { ...loaderData.cart.items[i].product },
      quantity: loaderData.cart.items[i].quantity,
      addedAt: loaderData.cart.items[i].addedAt,
    });
  }
  if (fetcher.formData?.get("intent") && fetcher.formData.get("item")) {
    const intent = fetcher.formData.get("intent");
    const item = JSON.parse(String(fetcher.formData.get("item")) || "");
    const itemIndex = items.findIndex((i) => {
      return (
        i.product.id === item.product.id &&
        i.product.variation?.id === item.product.variation?.id
      );
    });
    if (intent === INTENTS.INCREASE_QUANTITY) {
      items[itemIndex].quantity += 1;
    } else if (intent === INTENTS.DECREASE_QUANTITY) {
      items[itemIndex].quantity -= 1;
    } else if (intent === INTENTS.REMOVE_FROM_SHOPPING_CART) {
      items.splice(itemIndex, 1);
    }
  }

  return (
    <RightPanelWrapper
      isVisible={shoppingCartVisible === "shoppingCart"}
      onClickOutside={() => setShoppingCartVisible("hidden")}
    >
      <div className="flex h-full flex-col">
        <div className="flex flex-row justify-end">
          <div
            className="mt-4 flex h-10 w-10 cursor-pointer flex-row items-center justify-center"
            onClick={() => setShoppingCartVisible("hidden")}
          >
            <CloseIcon size="24" />
          </div>
        </div>
        {items.length === 0 ? (
          <div className="flex h-full flex-col items-center justify-center text-xl font-semibold">
            Karfan þín er tóm 💅
          </div>
        ) : (
          <div className="flex h-full flex-col">
            <div className="mt-10 flex flex-col overflow-y-auto pb-10">
              {items.map((item) => {
                const priceInfo = calculatePrice(
                  item.product.priceFrom,
                  item.product.discount,
                );
                return (
                  <div
                    key={`${item.product.id} - ${item.product.variation?.id || ""}`}
                  >
                    <div className="mt-4 flex flex-row items-center gap-4">
                      <div className="mr-6">
                        <img
                          src={GetSmallUrl(item.product.image.id)}
                          className="h-16"
                          alt="vara"
                        />
                      </div>
                      <div className="flex w-full flex-row items-center justify-between">
                        <div>
                          <div className="flex-1">{item.product.title}</div>
                          <div className="text-xs">
                            {item.product.variation?.title}
                          </div>
                        </div>

                        <fetcher.Form method="post">
                          <input
                            type="hidden"
                            name="item"
                            value={JSON.stringify(item)}
                          />
                          <input
                            type="hidden"
                            name="intent"
                            value={INTENTS.REMOVE_FROM_SHOPPING_CART}
                          />
                          <button className="flex h-10 w-10 flex-row items-center justify-center">
                            <CloseIcon size="16" />
                          </button>
                        </fetcher.Form>
                      </div>
                    </div>
                    <div className="flex h-10 flex-row items-center justify-end gap-x-2 text-lg font-bold">
                      <div
                        className={`flex h-full flex-row items-center gap-x-2 bg-primary px-2 ${fetcher.state === "submitting" ? "opacity-30" : "opacity-1"}`}
                      >
                        <fetcher.Form
                          method="post"
                          className="flex h-full flex-row items-center"
                        >
                          <input
                            type="hidden"
                            name="item"
                            value={JSON.stringify(item)}
                          />
                          <input
                            type="hidden"
                            name="intent"
                            value={
                              item.quantity === 1
                                ? INTENTS.REMOVE_FROM_SHOPPING_CART
                                : INTENTS.DECREASE_QUANTITY
                            }
                          />
                          <button
                            className="h-full px-2"
                            disabled={fetcher.state === "submitting"}
                          >
                            -
                          </button>
                        </fetcher.Form>
                        {item.quantity}
                        <fetcher.Form
                          method="post"
                          className="flex h-full flex-row items-center"
                        >
                          <input
                            type="hidden"
                            name="item"
                            value={JSON.stringify(item)}
                          />
                          <input
                            type="hidden"
                            name="intent"
                            value={INTENTS.INCREASE_QUANTITY}
                          />
                          <button
                            className="h-full px-2"
                            disabled={fetcher.state === "submitting"}
                          >
                            +
                          </button>
                        </fetcher.Form>
                      </div>
                      <div
                        className={`${priceInfo.hasDiscount ? "text-[#FF3636]" : ""}`}
                      >
                        {formatPrice(priceInfo.priceWithDiscount)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex-grow"></div>
            <div
              className={`text-center text-3xl font-bold ${fetcher.state === "submitting" ? "opacity-30" : "opacity-1"}`}
            >
              {formatPrice(calculateTotalPrice(loaderData.cart))}
            </div>
            <div className="mb-8 mt-4 w-full">
              <BigButton
                state={fetcher.state === "submitting" ? "disabled" : "enabled"}
                onClick={() => {
                  setShoppingCartVisible("hidden");
                  navigate("/checkout");
                }}
              >
                Klára kaup
              </BigButton>
              {amount >= LIMIT_TO_OFFER_FREE_SHIPPING &&
                amount < FREE_SHIPPING_AMOUNT && (
                  <H5>
                    <div className="mt-4 text-center">
                      Bættu við {formatPrice(missingForFreeShipping)} og fáðu
                      fría heimsendingingu
                    </div>
                  </H5>
                )}
            </div>
          </div>
        )}
      </div>
    </RightPanelWrapper>
  );
};
